// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These 
// styles are for the entire app and not just one component. Additionally, this 
// file can hold Sass mixins, functions, and placeholder classes to be imported 
// and used throughout the application.
/*GENERAL-STYLE*/
app-community-shops.ion-page {
    display: block !important;
}
.hm_menu_bay_transparent{
    padding: 5px;width:100%;
    background: url("../assets/img/background.png") !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 54%;
    font-size: 2.0em;
    color: #b5e3ff;
    
    width: 119%;
    opacity: 30%;
    z-index: -1;
    position: absolute;
    bottom: 0;


    

}

.hm_menu_bay_transparent_dark{
    padding: 5px;width:100%;
    background: url("../assets/img/background_dark.png") !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 54%;
    font-size: 2.0em;
    color: #b5e3ff;
    
    width: 119%;
    
    z-index: -1;
    position: absolute;
    bottom: 0;


    

}


.chalight{
    color: #b5e3ff;
}
.chamusic{
	margin: 2%;
}
.hm_clear{clear:both;}
.hm_bg_color{background: #162d38;border: 1px solid #162d38;}
.hm_border_none{border:none;}
.hm_border_left_none{border-left:none !important;}
.hm_border_right_none{border-right:none !important;}
.hm_border_top_none{border-top:none !important;}
.hm_border_bottom_none{border-bottom:none !important;}
.hm_header{height: 7%;width:102%;}
.hm_header_btn{
	color:#b5e3ff;
	font-weight: 300;
	padding-top: 15px;
	font-size: 16px;
	width:20%;
	height:40px;
	background:transparent;
	float: right;
	box-shadow: none !important;}
.hm_header_btn ion-icon{
	padding-right: 8px;
	font-size: 1.5em;

}
.chaadd{
	font-weight: 600;
	padding-left: 10px;
}

.hm_search_icon{background:url("../assets/img/hm_search_icon.png") !important;background-repeat: no-repeat !important;background-position: center !important;background-size: 20px !important;}
.hm_more_icon{background:url("../assets/img/hm_more.png") !important;background-repeat: no-repeat !important;background-position: center !important;background-size: 5px !important;}
.hm_back_icon{background:url("../assets/img/hm_left_arw_icon.png") !important;background-repeat: no-repeat !important;background-position: center !important;background-size: 12px !important;}
.hm_float_left{float: left !important;}
.hm_float_right{float: right !important;}
.hm_align_center{text-align: center;}
.hm_theme_trans_bg{background: rgba(0, 0, 0, 0.8) !important;}
.hm_border_top_none{border-top:none !important;}
.hm_border_bottom_none{border-bottom:none !important;}
.hm_border_left_none{border-left:none !important;}
.hm_border_right_none{border-right:none !important;}
.hm_padding0{padding:0px !important;}

/*IONIC-OVER-WRITTEN-STYLE*/

.hm_slider .swiper-pagination-bullet{background: #faae3f;}
.header-md::after, .tabs-md[tabsPlacement="top"] > .tabbar::after, .footer-md::before, .tabs-md[tabsPlacement="bottom"] > .tabbar::before {display:none !important;}

/*INTRODUCTION-PAGE*/

.hm_intro_bg{background:url("../assets/img/hm_intro_bg.png") !important;background-repeat: no-repeat !important;background-position: center bottom !important;background-size:cover !important;}
.hm_slider{width:100%;height:370px;}
.hm_slide_banner{width:100%;text-align: center;height: 150px;}
.hm_slide_banner img{width:220px;}
.hm_slide h4{color: #c8cbcd;font-size: 16px;}
.hm_slide p{color: #c8cbcd;font-size: 13px;line-height: 20px;}
.hm_slide_btm_bay{width:100%;position: fixed;bottom:0px;text-align: center;    padding-bottom: 25px;}
.hm_slide_btm_bay p{color: #fff;font-size: 13px;}
.hm_btm_btn{margin:0 auto;width:50px;height:50px;border-radius:50%;background: transparent;padding: 0px;}
.hm_btm_btn img{width:40px;object-fit: cover;object-position: center;}

/*SIGNIN-PAGE*/

.hm_sign_bg{
	--background: none;
	background:url("../assets/img/hm_theme_bg.png") !important;
	background-repeat: no-repeat !important;
	background-position: center bottom !important;
	background-size:cover !important;
} 
.hm_wrapper{width:100%;}
.hm_signin_inner_wrapper{width:85%;margin-top: 15% !important;margin: 0 auto;}
.hm_logo{width:100%;text-align: center;}
.hm_logo img{width:50px;}
.hm_logo h5{width:100%;text-align: center;color: #162d38;margin:0px;font-size: 30px;font-weight: 400;}
.hm_logo h6{width:100%;text-align: center;color: #162d38;margin:0px;font-size: 20px;font-weight: 300;}
.hm_sign_detail{width:100%;}
.hm_sign_detail p{color: #162d38;font-weight: 300;text-align: center;}
.hm_mobile_number_div{border:1px solid #faae3f;margin-bottom: 10px;}
.hm_sign_number_input{background: transparent;float:left;border:none;}
.hm_mobile_code option{color: #162d38;font-weight: 500;}
.hm_sign_btn{background-color: #162d38 !important;width:100%;height:35px;color: #e2e4e4;text-align: left;padding-left: 10px;font-size: 16px;font-weight: 300;background:url("../assets/img/hm_log_arw.png");background-repeat: no-repeat !important;background-position: right 10px top 12px !important;background-size: 13px;text-align: left !important;border-radius:0px !important;}
.hm_signup_input{width:100%;height:35px;border:1px solid #faae3f;margin-bottom: 10px;padding-left:45px;background: transparent !important;}
.hm_back_btn{width:35px;height:35px;background:url("../assets/img/hm_back_arrow.png") !important;background-repeat: no-repeat !important;background-position:center !important;background-size:12px !important;position: absolute;top:10px;left:10px;}
.hm_button{box-shadow:none !important;}
.hm_signin_inner_wrapper h3{text-align: center;color: #162d38;font-weight: 400;font-size: 25px;}
.hm_signin_inner_wrapper h4{text-align: center;color: #162d38;font-size: 16px;font-weight: 400;}
.hm_signin_inner_wrapper h5{text-align: center;color: #162d38;}
.hm_verification_code{width:100%;}
.hm_verification_code ul{margin:0px;padding:0px;text-align: center;}
.hm_verification_code ul li{display:inline-block;height:45px;width:55px;border:1px solid #faae3f;    margin: 5px;}
.hm_verification_code_select{width:100%;height:100%;-webkit-appearance: none;-moz-appearance: none;background: transparent;border:none !important;font-size: 20px;color: #6d767b;padding-left: 20px;}
.hm_verification_code_select option{text-align: center;color: #6c757a;}

.hm_general_btn{
	background-color: #162d38 !important;
	width:80%;
	height:35px;
	color: #e2e4e4;
	font-size: 16px;
	font-weight: 300;
	text-align: center !important;
	border-radius:0px !important;
	margin: 5%;
	margin-left: 12%;
	margin-top: 0;
}


.hm_mobile_number_div{border:1px solid #faae3f;}
.hm_sign_number_input{background: transparent;border:none;}
.hm_mobile_code option{color: #162d38;font-weight: 500;}






/*HOME-PAGE*/

.hm_header_location_bay{color: #fff;height: 40px;padding-left: 40px;padding-top:10px;font-size: 16px;font-weight: 300;width:100%;float: left;border:none !important;}
.hm_header_location_bay ion-icon{
	color: #b5e3ff;
	margin-left: -28px;
    margin-right: 4px;
    font-size: 1.5em;
	}
.hm_header_bay{height:45px;width:100%;}
.hm_header_location_bay span{padding-left: 10px;}
.hm_header_location_bay span img{width: 18px;}
.hm_search_bay{width:100%;padding: 4%;background: #162d38 !important;}
.hm_search_input{width:100%;border:none;height:35px;font-size: 16px;font-weight: 300;background-color: #fff !important;padding-left: 20px;background:url("../assets/img/hm_search_icon.png");background-repeat: no-repeat !important;background-position: right 7px top 6px !important;background-size: 15px !important;}
.hm_menu_bay{
	padding: 5px;width:100%;
	background: url("../assets/img/front_bg.png") !important;
	background-repeat: no-repeat !important;
	background-size: 100% 100% !important;
	height: 270px;
	font-size: 2.0em;
	color: #b5e3ff;

	

}
.hm_menu_bay ul{padding: 0px;margin:0px;text-align: center;width:100%;}
.hm_menu_bay ul li{width:33.33%;float: left;text-align: center;list-style:none;border:1px solid #2b404a;border-top:none;padding:10px;}
.hm_menu_bay ul li img{width:35px;}
.hm_menu_bay ul li p{margin:0px;color: #b3b7b9;font-size: 12px;padding-top: 5px;padding-bottom:5px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.hm_ad_wrap{width:100%;height:150px;}
.hm_ad_wrap img{object-fit: cover;object-position: center;width:100%;height:100%;}

.hm_ad_wrap ul{padding: 0px;margin:0px;text-align: center;width:100%;}
.hm_ad_wrap ul li{
	width:25%;
	float: left;
	text-align: center;
	list-style:none;
	border:none solid #2b404a;
	border-top:none;
	padding:10px;
}
.hm_ad_wrap ul li img{width:35px;}
.hm_ad_wrap ul li p{
	margin:0px;
	color: #000;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom:5px;
	 overflow: hidden;
	 text-overflow: ellipsis;
	}
.hm_ad_wrap ion-icon{
	color: #172d38;
    font-size: 1.5em;
	}
.hm_footer_tab{
	width: 100%;
	height: 50px;
	background: #172d38;
	position: fixed;
	bottom: 0px;
	z-index: 9;}
.hm_footer_tab ion-icon{
	color: #b5e3ff;
	font-size: 1.5em;
}
.hm_footer_tab ul li p {
    margin: -3px;
    color: #b3b7b9;
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 5px;
}
.hm_footer_tab ul{margin:0px;padding: 0px;height: 100%;}
.hm_footer_tab ul li{width:25%;float: left;list-style: none;text-align: center;height: 100%;padding: 10px;position: relative;}
.hm_footer_tab ul li img{height: 30px;}
.hm_catagory_scroll_div{
	width:100%;
	height:130px;
	margin-bottom:60px;
}

.hm_catagory_scroll_div ul {
	width: 700px;
	padding: 0px;
	margin:0px;
	padding-top: 10px;
}
.hm_catagory_scroll_div ul li{
	display: inline-block;
	padding-left:10px;}
.hm_catagory_item{
	width:85px;
	height:85px;
	border-radius:50%;
	background: #a8a8a8;
}
.hm_catagory_scroll_div ul li p{
	width:100%;
	text-align: center;
	color: #636363;
}

.hm_catagory_scroll_div p{
	margin:0px;
	color:#244454;
	padding-left: 15px;
	padding-top: 0px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 20px;
	padding-bottom: 10px;
	font-size: 16px;
}
.chaline{
	
	background-color: #172d38;
	background-size: 1px;
	height: 1px;
}
.chadvert .card-md {
	margin: 0px;
    width: 100%;
}
.chadvert .card-header-md {
    font-size: 1.6rem;
    color: #222;
    padding: 6px;
}
.hm_catagory_item img{width:100%;height:100%;object-fit: cover;object-position: center;border-radius:50%;}
.hm_red_circle{width:20px;height:20px;background: #ff0000;position: absolute;top: 5%;right: 16%;color: #fff;border-radius:50% !important;}

/*LOCATION-POP*/

.hm_search_header{float: left;height:100%;width:65%;color: #fff;font-size: 16px;font-weight: 300;padding: 10px;}
.hm_search_locality_input{height: 100%;width:100%;background: transparent;border:none;color: #fff;}
.hm_inner_content{width:85%;margin:0 auto;}
.hm_get_my_loc{color: #162d38;padding-top: 10px;padding-bottom:10px;font-size: 18px;}
.hm_get_my_loc span img{width:25px; position: relative;top: 5px;margin-right: 8px;}
.hm_recent_search{width:100%;}
.hm_recent_search_head{color: #757d82;padding-top: 10px;padding-bottom:10px;font-size: 18px;}
.hm_recent_search_head span img{width:25px; position: relative;top: 5px;margin-right: 8px;}
.hm_recent_search ul{margin:0px;    padding-left: 35px;}
.hm_recent_search ul li{list-style: none;color: #969696;font-size: 14px;padding-bottom: 10px;}

/*SERVICES-PAGE*/

.hm_services{width:100%;height:100%;}
.hm_service_menu_bar{width:100%;background: #162d38;height:45px;position: fixed;top:44px;z-index: 9;}
.hm_service_menu_bar ul {width:820px;margin:0px;padding:10px;height: 100%;margin-top: 3px;}
.hm_service_menu_bar::-webkit-scrollbar { display: none;}
.hm_service_menu_bar::-moz-scrollbar { display: none;}
.hm_service_menu_bar ul li{float: left;list-style: none;height: 100%;}
.hm_service_menu_bar ul .active a{padding-left: 30px;border:1px solid #fff;border-radius:20px;}
.hm_service_menu_bar ul li a{padding:5px;padding-left: 15px;padding-right: 15px;color: #8e9497;border:1px solid transparent;position:relative;}
.hm_service_menu_bar ul li a span{position: absolute;left:10px;font-size: 35px;top:-18px;}
.hm_service_slide{width:100%;height:100%;}
.hm_service_content{width:100%;height:100%;}
.hm_service_list{width:100%;position: absolute;top:65px;text-align:left;}
.hm_service_list ul{margin:0px;padding: 0px;}
.hm_service_list ul li{float: left;width:33.3%;list-style: none;text-align: -webkit-center;text-align: -moz-center;}


.active .beauty{border-color:#ee4e9c !important;color: #f1f1f1;border-radius:20px;}
.active .health{border-color:#2bb673 !important;color: #f1f1f1;border-radius:20px;}
.active .lesson{border-color:#fcb040 !important;color: #f1f1f1;border-radius:20px;}
.active .homeservice{border-color:#d7df21 !important;color: #f1f1f1;border-radius:20px;}
.active .business{border-color:#25aae1 !important;color: #f1f1f1;border-radius:20px;}
.active .event{border-color:#8dc63f !important;color: #f1f1f1;border-radius:20px;}
.active .hobbie{border-color:#1fb6a8 !important;color: #f1f1f1;border-radius:20px;}
.active .repair{border-color:#9476b5 !important;color: #f1f1f1;border-radius:20px;}


.beauty .hm_service_div{background:url("../assets/img/hm_pink_elipse.png") !important;width:60px;height:60px;background-size: cover !important;padding:12px;}
.health .hm_service_div{background:url("../assets/img/hm_green_elipse.png") !important;width:60px;height:60px;background-size: cover !important;padding:12px;}
.lesson .hm_service_div{background:url("../assets/img/hm_yellow_elipse.png") !important;width:60px;height:60px;background-size: cover !important;padding:12px;}
.homeservice .hm_service_div{background:url("../assets/img/hm_lime_elipse.png") !important;width:60px;height:60px;background-size: cover !important;padding:12px;}
.business .hm_service_div{background:url("../assets/img/hm_blue_elipse.png") !important;width:60px;height:60px;background-size: cover !important;padding:12px;}
.event .hm_service_div{background:url("../assets/img/hm_agreen_elipse.png") !important;width:60px;height:60px;background-size: cover !important;padding:12px;}
.hobbie .hm_service_div{background:url("../assets/img/hm_cyan_elipse.png") !important;width:60px;height:60px;background-size: cover !important;padding:12px;}
.repair .hm_service_div{background:url("../assets/img/hm_purple_elipse.png") !important;width:60px;height:60px;background-size: cover !important;padding:12px;}
.beauty .hm_service_more{background:url("../assets/img/hm_pink_more.png") !important;width:60px;height:60px;background-size: cover !important;padding:10px;}
.health .hm_service_more{background:url("../assets/img/hm_green_more.png") !important;width:60px;height:60px;background-size: cover !important;padding:10px;}
.lesson .hm_service_more{background:url("../assets/img/hm_yellow_more.png") !important;width:60px;height:60px;background-size: cover !important;padding:10px;}
.homeservice .hm_service_more{background:url("../assets/img/hm_lime_more.png") !important;width:60px;height:60px;background-size: cover !important;padding:10px;}
.business .hm_service_more{background:url("../assets/img/hm_blue_more.png") !important;width:60px;height:60px;background-size: cover !important;padding:10px;}
.event .hm_service_more{background:url("../assets/img/hm_agreen_more.png") !important;width:60px;height:60px;background-size: cover !important;padding:10px;}
.hobbie .hm_service_more{background:url("../assets/img/hm_cyan_more.png") !important;width:60px;height:60px;background-size: cover !important;padding:10px;}
.repair .hm_service_more{background:url("../assets/img/hm_purple_more.png") !important;width:60px;height:60px;background-size: cover !important;padding:10px;}
.hm_service_div img{width:30px;}
.hm_service_list ul li p{color: #6c6c6c;text-align: center;margin:0px;font-size: 15px;padding-top: 10px;padding-bottom: 20px;}


/*FITNESS-INNER*/


.hm_wrapper_inner{width:100%;height:100%;}
.hm_wizard_wrapper{width:100%;height:100%;}
.hm_wizard_slide{width:100%;height:100%;}
.hm_wizard_footer{width:100%;padding:20px;}
.hm_wizard_footer_btn{background-color: #162d38 !important;width:180px;height:35px;color: #e2e4e4;text-align: left !important;padding-left: 10px;font-size: 16px;font-weight: 300;background:url("../assets/img/hm_log_arw.png");background-repeat: no-repeat !important;background-position: right 10px top 12px !important;background-size: 13px;border-radius:0px !important;text-transform: capitalize;}
.hm_wizard_footer p{color:#747474;font-size:12px;}
.hm_wizard_progress{width:100%;}
.hm_wizard_progress ul{width:100%;margin:0px;padding:0px;position:relative;top:2px;}
.hm_wizard_progress ul li{width:25%;float:left;list-style:none !important;}
.hm_progress0{border:4px solid #2bb673;border-radius:20px;width:0%;border-bottom-left-radius: 0px;border-top-left-radius: 0px;}
.hm_progress1{border:4px solid #2bb673;border-radius:20px;width:25%;border-bottom-left-radius: 0px;border-top-left-radius: 0px;}
.hm_progress2{border:4px solid #2bb673;border-radius:20px;width:50%;border-bottom-left-radius: 0px;border-top-left-radius: 0px;}
.hm_progress3{border:4px solid #2bb673;border-radius:20px;width:100%;border-bottom-left-radius: 0px;border-top-left-radius: 0px;}
.hm_progress_deactive{border:4px solid transparent;}
.hm_wizard_completion{width:100%;height:100%;padding:10px;padding-left:25px;padding-right:25px;}
.hm_wizard_progress_info{width:100%;color:#162d38;text-align:left;border-bottom:1px solid #cdcdcd;padding-top:20px;padding-bottom:20px;}
.hm_wizard_progress_info strong{font-size:23px;}
.hm_wizard_slide{width:100%;height:100%;}
.hm_wizard_slide .slide-zoom{width:100%;height:100%;}
.hm_wizard_content{width:100%;height:100%;    text-align: left;}
.hm_wizard_content h5{color:#162d38;font-size:18px;text-transform:uppercase;}
.hm_wizard_content ul{margin:0px;padding:0px;padding-top: 8px;}
.hm_wizard_content ul li{color:#162d38;font-size:14px;background:url("../assets/img/hm_dot.png");background-repeat: no-repeat !important;background-position: left 0px top 3px !important;background-size: 8px;padding-left:20px;padding-bottom: 10px;list-style:none;}
.hm_radio_custom{width:100%;padding-bottom:10px;}
.hm_radio_div{float:left;}
.hm_radio_label{float:left;color:#162d38;font-size:14px;padding-left:15px;}
.hm_radio_custom .radio-icon{border-color:#162d38 !important;}
.hm_radio_custom .radio-checked .radio-inner{background:#2bb673 !important;}
.hm_wizard_content p{color: #747474;font-size: 12px;}
.hm_location_input{background: url(../assets/img/hm_location_icon.png),url(../assets/img/hm_edit.png) !important;background-repeat: no-repeat !important;color: #a8a8a8;height: 40px;padding-left:25px;font-size: 16px;font-weight: 300;background-size: 14px !important;background-position: left 0px top 10px,right 10px top 10px !important;width:100%;border: none !important;}
.hm_getlocation_btn{background-color: #414141 !important;width: 100%;height: 35px;color: #e2e4e4;text-align: left !important;padding-left: 10px;font-size: 16px;font-weight: 300;background: url(../assets/img/hm_location.png);background-repeat: no-repeat !important;background-position: right 10px top 12px !important;background-size: 13px;border-radius: 0px !important;text-transform: capitalize;}


/*WIZARD-POP-0VER*/


.hm_popover_main{width:180px !important;background:#fff !important;float:right;position:relative;right:15px;}
.hm_popover_main ul{margin:0px;padding:0px;}
.hm_popover_main ul li{width:100%;list-style:none;color:#4f4f4f;font-size: 18px;padding:10px;}
.hm_popover_main ul li span img{width:20px;position:relative;top:3px;margin-right:8px;}
.popover-content {width:100% !important;top:50px !important;left:0px !important;right:0px !important;background:transparent !important;box-shadow:none !important;}
.hm_popover_cover{width:100%;position:relative;}
.popover-content ion-content, .popover-content .scroll-content {contain: none;background: transparent !important;}
ion-popover{background:rgba(0,0,0,0.8)}




  .chat-popover-class{
    width: 100%;

  }
.chat-popover-class {background:rgba(0,0,0,0.1) !important}
.chat-popover-class .popover-content {
    width: 53% !important;
    top: 50px !important;
    left: 44% !important;
    right: 0px !important;
    background: transparent !important;
    box-shadow: none !important;
}


.community-popover-class{
    width: 100%;

  }
.community-popover-class {background:rgba(0,0,0,0.1) !important}
ion-popover {
  --background: rgba(40, 173, 218, 0.6);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 88%;
}





/*WIZARD-SUCCESS-PAGE*/


.hm_wizardsuccess{text-align:center !important;padding-top:30%;}
.hm_wizardsuccess span img{width:50px}


/*REQUIREMENT-PAGE*/


.hm_requirement{width:100%;}
.hm_requirement ul{width:100%;margin:0px;padding:0px;}
.hm_requirement ul li{width:100%;list-style:none !important;background:url("../assets/img/hm_dot1.png");background-repeat: no-repeat !important;background-position: left 0px top 3px !important;background-size: 8px;padding-left:20px;padding-bottom: 10px;}
.hm_requirement ul li p{color:#5f5f5f;}
.hm_requirement ul li h4{color:#162d38;}


/*CANCEL-POP-UP*/


.hm_cancel_pop_div{width:100%;height:100%;}
.hm_cancel_pop_main{width:300px;background:#ffffff;margin:0 auto;position:relative;top:100px;}
.hm_cancel_pop_upper{width:100%;background:#172d38;text-align:center;padding: 30px;}
.hm_cancel_pop_upper img{width:50px;}
.hm_cancel_pop_upper p{color:#d2d4d6;font-size:18px;margin:0px;padding-top:10px;}
.hm_cancel_pop_lower{width:100%;padding:20px;}
.hm_cancel_pop_lower ul{width:100%;margin:0px;padding:0px;}
.hm_cancel_pop_lower ul li{width:100%;list-style:none;font-weight: 300;font-size: 15px;padding-bottom:15px;font-size:15px;}
.hm_cancel_reason{float:left;color:#888888;padding-left:10px;}
.hm_cancel_radio{float:left;}
.hm_cancel_bottom_bay{width:100%;text-align:center;padding-top: 15px;}
.hm_cancel_bottom_bay li{display:inline-block;list-style:none;padding:8px;padding-left:20px;padding-right:20px;font-size:18px;font-weight:500;border-radius:20px;}
.hm_cancel_cancel{color:#556067;}
.hm_cancel_submit{background-color:#162d38 !important;color:#fff;background:url("../assets/img/hm_log_arw.png");background-repeat: no-repeat !important;background-position: right 10px top 13px !important;background-size: 13px;padding-right:40px !important;font-weight:300 !important;}
.hm_cancel_radio .radio-md .radio-checked{border-color:#faaf41 !important;}
.hm_cancel_radio .radio-inner{background:#faaf41 !important;}


/*MY-PROFILE*/


.hm_myprofile_photo{width:120px;height:120px;border-radius:50%;position:relative;}
.hm_myprofile_photo img{width:100%;height:100%;border-radius:50%;object-fit:cover;object-position:center;}
.hm_edit_circle{width:30px;height:30px;border-radius:50%;position:absolute;top:0px;right:0px;}
.hm_edit_circle img{width:100%;height:100%;border-radius:50%;}
.hm_myprofile_detail{width:100%;padding-top:20px;}
.hm_myprofile_left{width:70%;float:left;border-right:1px solid #a2a2a2;}
.hm_myprofile_right{width:20%;float:right;position:relative;}
.hm_myprofile_left h4{margin:0px;color:#162d38;}
.hm_myprofile_left p{margin:0px;color:#162d38;padding-top: 5px;padding-bottom: 5px;}
.hm_myprofile_left h6{margin:0px;color:#162d38;}
.hm_edit_circle1{width:30px;height:30px;border-radius:50%;position:absolute;top:15px;}
.hm_edit_circle1 img{width:100%;height:100%;border-radius:50%;}
.hm_myprofile_list{width:100%;}
.hm_myprofile_list ul{width:100%;margin:0px;padding:0px;margin-bottom:50px;}
.hm_myprofile_list ul li{width:100%;list-style:none;color:#162d38;font-size:16px;padding-bottom: 10px;border-bottom:1px solid #d8d8d8;padding-top:10px;}
.hm_myprofile_list ul li span{position:relative;top:6px;margin-right:10px;}
.hm_myprofile_list ul li span img{width:25px;height:25px;}
.hm_myprofile_list .hm_address_arrow  span img{width:15px;height:15px;}
.hm_myprofile_list .hm_address_arrow  span{position:relative;top:6px;margin-right:10px;}
.hm_myprofile_list .hm_address_arrow{float:right;border-left:none;padding-left:20px;}
.hm_myprofile_detail hr{border:1px solid #a2a2a2;height:0px;border-top:none;width:50px;float:left;}


/*MESSAGE*/


.hm_message_div{width:100%;}
.hm_message_div ul{width:100%;margin:0px;padding:0px;}
.hm_message_div ul li{width:100%;list-style:none;border-bottom:1px solid #c8c8c8;padding-top:15px;padding-bottom:15px;}
.hm_message_pic{width:70px;height:70px;border-radius:50%;background:#162d38;float:left;position:relative;}
.hm_message_pic img{width:100%;height:100%;border-radius:50%;}
.hm_message_not{width:20px;height:20px;border-radius:50%;background:#528847;color:#fff;text-align:center;position:absolute;right:0px;top:0px;}
.hm_message_detail{float:left;width:55%;padding-left:15px;padding-top:15px;}
.hm_message_detail h4{margin:0px;color:#162d38;  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size:16px;padding-bottom:8px;}
.hm_message_detail p{margin:0px;color:#767676;white-space:nowrap;overflow: hidden;text-overflow: ellipsis;}
.hm_message_date{float:right;border-left:1px solid #a2a2a2;padding-top:15px;padding-bottom:15px;padding-left:15px;}
.hm_message_date h6{color:#a2a2a2;margin:0px;padding:0px;font-weight:400;padding-bottom:5px;}
.hm_message_date p{color:#5d5d5d;margin:0px;padding:0px;} 


/*CHAT*/


.hm_chat_wrap{width:100%;}
.hm_chat_wrap ul{margin:0px;padding:0px;}
.hm_chat_wrap ul li{list-style:none;padding-bottom:20px;}
.hm_message_photo{width:50px;height:50px;border-radius:50%;}
.hm_message_photo img{width:100%;height:100%;border-radius:50%;}
.hm_message_content{padding-top:15px;}
.hm_message_content h6{margin:0px;padding-bottom: 10px;font-weight: 400;}
.hm_message_content p{margin:0px;padding:15px;border-radius:30px;padding-left:30px;padding-right:30px;    margin-bottom: 10px;}
.hm_message_content span{margin:0px;color:#767676;}
.hm_received{}
.hm_sented{}

.hm_received .hm_message_photo{float:left;background:#162d38;}
.hm_received .hm_message_content{float:left;text-align:left;padding-left:15px;}
.hm_received .hm_message_content p{background:#f9ae3e;color:#fff;border-top-left-radius: 0px;}
.hm_received .hm_message_content span{margin-right:10px;}

.hm_sented .hm_message_photo{float:right;background:#383838;}
.hm_sented .hm_message_content{float:right;text-align:right;padding-right:15px;}
.hm_sented .hm_message_content p{background:#535353;color:#fff;border-top-right-radius: 0px;}
.hm_sented .hm_message_content span{margin-left:10px;}

.hm_chat_type_wrapper{width:100%;background:#fff;height:50px;}
.hm_attach{float:left;height:100%;background:transparent;padding-left:10px;padding-right:10px;}
.hm_attach img{height:20px;}
.hm_camera{float:left;height:100%;background:transparent;padding-left:10px;padding-right:10px;}
.hm_camera img{height:20px;}
.hm_send_btn{float:right;height:100%;background:transparent;padding-left:10px;padding-right:10px;}
.hm_send_btn img{height:40px;}
.hm_type_text{float:left;height:100%;border:none;color:#bcbcbc;padding-left:10px;}


/*MY-PROJECTS*/


.hm_project_tab{width:100%;background:#162d38;}
.hm_project_tab ul{width:100%;margin:0px;padding:0px;padding:15px;}
.hm_project_tab ul li{list-style:none;display:inline-block;width:45%;text-align:center;color:#fff;font-size:13px;padding:3px;}
.hm_project{width:100%;height:100%;}
.hm_project_tab ul .active{border:1px solid #fff;border-radius:20px;}
.hm_project_tab ul li span{color:#162d38;}
.hm_project_tab ul .active span{color:#fff !important;margin-right:10px;position:relative;bottom:4px;}
.hm_project_tab_content{width:100%;padding:10px;padding-top:20px;}
.hm_project_tab_content h5{color:#555555;font-size:20px;position:relative;margin:0px;}
.hm_project_tab_content h5 span{position:absolute;top:0px;right:0px;}
.hm_project_tab_content h5 span img{height:20px;}
.hm_response{width:100%;padding-top:15px;}
.hm_response_left{float:left;color:#c3c3c3;font-weight:300;font-size:18px;}
.hm_response_left strong{color:#162d38;font-size:18px;font-weight:600;margin-left:10px;}
.hm_response_right{float:right;}
.hm_show_all{color:#fff;background:#f9ae3e;padding:5px;border-radius:20px;padding-left:15px;padding-right:20px;position:relative;bottom:3px;}
.hm_show_all span img{position:relative;left:8px;width:10px;bottom: 1px;}
.hm_response_list{width:1400px;margin-top: 5px;}
.hm_response_list ul{padding:0px;margin:0px;width:100%;}
.hm_response_list ul li{background:#f1f1f1;display:inline-block;padding:10px;}
.hm_response_photo{width:45px;height:45px;background:#162d38;border-radius:50%;float:left;}
.hm_response_details{float:left;width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color:#858585;position:relative;top:10px;padding-left: 10px;font-size: 20px;}
.hm_show_all_deactive{background:#d6d6d6 !important;color:#fff !important;}


/*PROJECT-LIST*/


.hm_project_list{width:100%;}
.hm_project_list ul{width:100%;margin:0px;padding:0px;}
.hm_project_list ul li{width:100%;}
.hm_message_date h5{color:#162d38;}
.hm_step{color:#fff;background:#fcb040;padding-left:15px;padding-right:15px;float:left;padding-top: 3px;padding-bottom: 3px;position: relative;top: 9px;border-radius:20px;}
.hm_message_detail p span img{height:15px;width:auto;margin-right:5px;}
.hm_message_detail p span{margin-right:20px;}
.hm_hired_btn{border-radius:20px;background:#fcb040;color:#fff;font-weight:400;width:100%;font-size:18px;padding:10px;height:40px;text-transform:capitalize !important;font-weight: 300;}
.hm_btn_bottom{width:100%;padding:15px;}


/*FEED-BACK*/


.hm_feedback_wrapper{width:100%;}
.hm_feedback_wrapper li{border:none !important;}
.hm_ratting{width:100%;}
.hm_ratting p{color:#767676;font-size: 20px;margin-bottom: 10px;}


/*RATTING*/


.hm_ratting fieldset, label { margin: 0; padding: 0; }
.hm_ratting .rating { border: none;float: left;}
.hm_ratting .rating > input { display: none; } 
.hm_ratting .rating > label:before { margin: 5px;font-size:23px;font-family: FontAwesome;display: inline-block;content: "\f005";}
.hm_ratting .rating > .half:before { content: "\f089";position: absolute;}
.hm_ratting .rating > label { color: #ddd; float: right; }
.hm_ratting .rating > input:checked ~ label, /* show gold star when clicked */
.hm_ratting .rating:not(:checked) > label:hover, /* hover current star */
.hm_ratting .rating:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */
.hm_ratting .rating > input:checked + label:hover, /* hover current star when changing rating */
.hm_ratting .rating > input:checked ~ label:hover,
.hm_ratting .rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.hm_ratting .rating > input:checked ~ label:hover ~ label { color: #faae3f;  } 

.hm_feedback_text{width:100%;background:transparent;border:1px solid #a2a2a2;text-align:left;font-style:italic;color:#b7b7b7;padding:10px;font-size:16px;}
.hm_ratting_submit{color:#fff;border-radius:20px;background:#086327;color:#fff;font-weight:400;width:200px;font-size:18px;padding:10px;height:40px;text-transform:capitalize !important;font-weight: 300;}


/*RECOMMENDED*/


.hm_recommended_wrapper{width:100%;height:100%;}
.hm_recommended_banner{width:100%;height:300px;position:relative;}
.hm_recommended_banner img{width:100%;height:100%;object-fit:cover;object-position:center;}
.hm_recommended_banner_overlay{position:absolute;top:0px;bottom:0px;left:0px;right:0px;background:url("../assets/img/hm_spa_banner_overlay.png");background-repeat: no-repeat !important;background-position:center !important;background-size:cover !important;}
.hm_recommended_banner_text{position:absolute;padding:30px;bottom:0px;}
.hm_recommended_banner_text h4{color:#fff;margin:0px;font-size:30px;padding-bottom: 5px;}
.hm_recommended_banner_text p{color:#fff;margin:0px;font-size: 16px;}
.hm_howitwork{width:100%;border:1px solid #575757;border-radius:20px;height:45px;color:#575757;font-size:24px;background:transparent;}
.hm_howitwork span img{width:25px;margin-right:15px;position:relative;top:5px;}
.hm_howitwork span{font-size:22px;position: relative;bottom: 3px;}
.hm_recommended_detail{padding:15px;}
.hm_recommended_list{width:100%;}
.hm_recommended_list ul{margin:0px;padding:0px;}
.hm_recommended_list ul li{width:50%;float:left;list-style:none;border:1px solid #e7e7e7;padding:15px;height:75px;}
.hm_rec_list_left{float:left;}
.hm_rec_list_left img{height:30px;width:auto;}
.hm_rec_list_right{float:left;padding-top:3px;width:70%;padding-left:10px;}
.hm_rec_list_right h5{margin:0px;padding:0px;color:#6a6b6b;font-size:14px;padding-bottom:5px; white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;}
.hm_rec_list_right h6{margin:0px;padding:0px;color:#6a6b6b;font-weight:300;font-size:12px; white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;}
.hm_recommended_list h4{color:#162d38;}
.hm_recommended_list h4 span{position:relative;}
.hm_recommended_list h4 span img{width:30px;position:relative;top:6px;margin-right:10px;}
.hm_experience{width:100%;}
.hm_test_video_slider{width:100%;height:270px;}
.hm_test_video_slider ion-slide{width:100%;height:270px;position:relative;}
.hm_test_video_slider_overlay{position:absolute;top:0px;bottom:0px;left:0px;right:0px;background-color:rgba(0,0,0,0.3) !important;background:url("../assets/img/hm_play.png");background-repeat: no-repeat !important;background-position:center !important;background-size:60px;}
.hm_test_video_slider ion-slide img{width:100%;height:270px;object-fit:cover;object-position:center;}
.hm_experience_thumbnail{width:100%;}
.hm_thumb_video_slider{width:100%;height:100px !important;}
.hm_thumb_video_slider ion-slide{height:100px !important;width:150px !important;}
.hm_thumb_video_slider ion-slide img{width:100%;height:100px;object-fit:cover;object-position:center;}
.hm_testimonial{width:100%;}
.hm_testimonial{width:100%;}
.hm_testimonial_slider{width:100%;height:220px;}
.hm_testimonial_slider ion-slide{width:100%;height:220px;position:relative;background:#f4f4f4;border:2px solid #fff;padding:20px;}
.hm_testimonial_pic{width:60px;height:60px;border-radius:50%;background:#a8a8a8 !important;float:left;}
.hm_testimonial_pic img{width:100%;height:100%;object-fit:cover;object-position:center;}
.hm_testimonial_detail{float:left;text-align:left;padding-left:15px;padding-top:10px;}
.hm_testimonial_detail h5{color:#575757;margin:0px;padding:0px;font-size:16px;}
.hm_testimonial_detail p{color:#737373;margin:0px;padding:0px;font-weight:400;font-size:12px;padding-top:5px;}
.hm_testimoanil_p{width:100%;text-align:justify;color:#575757;font-weight:300;padding-top:20px;font-size:16px;}

.hm_service_listing{width:100%;height:500px;padding-top:50px;}
.hm_service_listing ul{padding:0px;margin:0px;}
.hm_service_listing li{width:100%;list-style:none;border-bottom:1px solid #d8d8d8;padding-bottom:20px;padding-top:20px;}
.hm_service_details{float:left;text-align:left}
.hm_service_details h5{margin:0px;color:#6d6d6d;font-weight: 400;font-size: 16px;}
.hm_service_details p{margin:0px;color:#868686;font-weight:300;padding-top:5px;padding-bottom:5px;    font-size: 14px;font-size: 11px;}
.hm_service_details h6{margin:0px;color:#162d38;font-size: 18px;font-weight: 400;}
.hm_service_add{float:right;border-left:1px solid #d8d8d8;padding-top:20px;padding-bottom:20px;padding-left:20px;}
.hm_service_btn{color:#fff;background:#f9ae3e;border-radius:20px;height:30px;padding-left:10px;padding-right:10px;text-align:center;font-size:16px;font-weight:500;}
.hm_allservice_footer{height:40px;width:100%;border-radius:20px;background:#2c2c2c;padding:10px;position:relative;}
.hm_amount{float:left;color:#fff;border-left:1px solid #676767;padding-left:15px;font-size: 16px;font-weight:300; }
.hm_items{float:left;color:#fff;width:20px;height:20px;border:1px solid #fff;border-radius:50%;text-align:center;font-size: 12px;padding:2px;}
.hm_allservice_footer span{float:left;margin-left:10px;margin-right:10px;}
.hm_allservice_footer span img{width:25px;}
.hm_service_continue{position:absolute;top:0px;right:-3px;bottom:-3px;height:40px;background-color:#f9ae3e !important;color:#fff;border-radius:20px;font-size:16px;padding-left:30px;padding-right:40px;padding-top:9px;    background: url(../assets/img/hm_show_arw.png);
    background-repeat: no-repeat !important;
    background-position: right 15px top 15px !important;
    background-size: 13px;
    text-align: left !important;border:1px solid #f9ae3e;}
	
/*ADD-POP-UP*/


.hm_add_pop_div{width:100%;height:100%;position:relative;}
.hm_add_pop_inner{width:300px;height:100%;position:relative;margin:0 auto;}
.hm_add_pop_inner h6{}
.hm_add_pop_wrapper{width:100%;background:#fff;position:absolute;bottom:0px;}
.hm_add_close{width:100%;position:absolute;top:-50px;text-align:center;}
.hm_add_close img{width:30px !important;}
.hm_add_pop_banner{width:100%;height:200px;position:relative;}
.hm_add_pop_banner img{width:100%;height:100%;object-fit:cover;object-position:center;}
.hm_add_detail{width:100%;padding:20px;}
.hm_add_name_bay{width:100%;border-bottom:1px solid #c9c9c9;padding-bottom:15px;}
.hm_add_name_bay h6{margin:0px;padding:0px;color: #818181;font-size: 19px;font-weight: 400;    padding-bottom: 8px;}
.hm_add_name_bay span{color:#898989;font-size:16px;margin-right:10px;}
.hm_add_name_bay .price{color:#464646;}
.hm_add_list{width:100%;}
.hm_add_list ul{padding-left:15px;}
.hm_add_list ul li{color:#979797;font-size: 16px;padding-bottom: 5px;}
.hm_add_list h6{color:#757575;font-weight:400;}


/*SUMMARY*/


.hm_service_listing h6{color:#162d38;font-weight:300;font-size:16px;}
.hm_service_listing_low_bay{width:100%;padding-top: 10px;}
.hm_toggle_btn{float:left;border:1px solid #f9ae3e;border-radius:20px;font-weight:500;padding: 4px;}
.hm_toggle_btn span{color:#f9ae3e;padding-left:10px !important;padding-right:10px !important;font-size:16px;}
.hm_service_listing_low_bay span{padding-left:10px;padding-right:10px;padding: 4px;}
.hm_toggle_btn1{float:left;padding: 4px;}
.hm_service_listing_low_bay span{font-size:18px;}
.hm_toggle_btn2{float:right;padding: 4px;border-left: 1px solid #d8d8d8;padding-left:30px;}


/*ADDRESS*/


.hm_address_bay{width:100%;padding-bottom:15px;}
.hm_address_section{float:left;color:#8a8a8a;font-size:12px;font-weight:300;}
.hm_address_arrow{float:right;border-left:1px solid #a8a8a8;padding-left:40px;}
.hm_add_address{height:30px;border-radius:20px;border:1px solid #575757;background:transparent;color:#8a8a8a;font-size:16px;padding-left:20px;padding-right:20px;}
.hm_add_address span{color:#f9ae3e;margin-left:10px;}
.hm_date_selector{width:100%;}
.hm_date_selector ul{width:750px;}
.hm_date_selector ul li{display:inline-block;border-right:1px solid #b0b0b0;border-bottom:none;padding:0px;width:65px;height:65px;padding:5px;}
.hm_date_selector ul li{margin:0px;color:#6b7479;font-size:14px;font-weight: 300;  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.hm_date_selector ul li strong{color:#6b7479;font-size: 16px;padding-top:5px;padding-bottom:5px;}
.hm_date_selector ul li:hover{background:#f9ae3e;color:#fff !important;border:none !important;}
.hm_date_selector ul li strong:hover{color:#fff;}
.hm_schedule{width:100%;}
.hm_schedule ul{margin:0px;padding:0px;text-align:center;padding-top:20px;padding-bottom:30px;}
.hm_schedule ul li{display:inline-block;width:47%;border:1px solid #b7b7b7;border-radius:20px;height:30px;padding:8px;margin:3px;color:#6b7479;font-size:11px;}
.hm_schedule ul li:hover{background:#f9ae3e;color:#fff !important;border:none;border:1px solid #f9ae3e;}
.hm_service_listing strong{color:#162d38;font-size:16px;font-weight:500;}
.hm_payment_div{width:100%;background:#f6f6f6;margin-top:20px;padding:20px;}
.hm_payment_tab{width:100%;}
.hm_payment_tab ul{margin:0px;padding:0px;}
.hm_payment_tab ul li{padding:0px;display:inline-block;border:none;width:100px;text-align:center;color:#969696;border:2px solid #f6f6f6;padding:3px;padding-left:15px;border-radius:20px;position:relative;}
.hm_payment_tab ul li span{color:#f4f4f4;position:absolute;left:10px;font-size:30px;top: -15px;}
.hm_payment_tab ul .active{border:2px solid #fcb040;color:#6b6b6b; font-weight: 500;}
.hm_payment_tab ul .active span{color:#fcb040;}
.hm_payment_content{width:100%;padding-top:20px;}
.hm_payment_content ul{margin:0px;padding:0px;}
.hm_payment_content ul li{width:100%;padding:0px;border-bottom:none;padding-bottom: 15px;}
.hm_payment_content .hm_cancel_reason{color:#162d38;font-size:17px;}
.hm_promo{width:100%;height:35px;width:100%;border:1px solid #b7b7b7;border-radius:20px;margin-top:15px;padding-left:20px;}
.hm_promo_btn{float:right;height:100%;border-radius:20px;background:#162d38;color:#fff;padding-left:30px;padding-right:30px;font-size: 16px;font-weight:400;}
.hm_promo_input{height:100%;border:none;font-size: 16px;}
.hm_summary_report{width:100%;}
.hm_summary_report ul{margin:0px;padding:0px;}
.hm_summary_report ul li{width:100%;padding:0px;border:none;color:#757575;font-size: 16px;padding-bottom:5px;}
.hm_summary_report .child1{float:left;width:50%;text-align:left;}
.hm_summary_report .child2{float:right;width:50%;text-align:right;}
.hm_summary_report strong{color:#162d38;font-size:18px;}


/*ADD-NEW-ADDRESS*/


.hm_newaddress_field{width:100%;}
.hm_newaddress_field ul{padding:0px;margin:0px;}
.hm_newaddress_field ul li{width:100%;border:none;padding:0px;padding-bottom:10px;}
.hm_new_input{height:40px;border:1px solid #cccccc;width:100%;padding-left:20px;color:#a6abae;font-size:16px;background:transparent;}
.hm_bottom_save{height:40px;color:#fff;border-radius:20px;background:#6ca354;padding-left:30px;padding-right:30px;    font-size: 16px;}
.hm_bottom_save span{}
.hm_bottom_save span img{width: 12px;margin-left: 10px;}
.hm_bottom_save1{height:40px;color:#fff;border-radius:20px;background:#f9ae3e;padding-left:30px;padding-right:30px;    font-size: 16px;}
.hm_bottom_save1 span{}
.hm_bottom_save1 span img{width: 12px;margin-left: 10px;}
.hm_cancel_radio label{color:#162d38;font-size:14px;padding-left:5px;padding-right:10px;position: relative;bottom: 3px;font-size: 16px;}


/*VIEW-PROFILE*/


.hm_view_profile_pic{width:80px;height:80px;background:#f4f4f4;border-radius:50%;float:left;margin: 15px;margin-left:0px;margin-right:15px;}
.hm_view_profile_detail{float:left;border-left:1px solid #c3c3c3;padding:5px;width:60%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;    padding-left: 15px;padding-bottom:15px;}
.hm_view_profile_banner{border-bottom:1px solid #c3c3c3;}
.hm_view_profile_detail h4{margin:0px;color:#162d38;padding-bottom:8px;font-size: 16px;}
.hm_view_profile_detail span{color:#8a8a8a;}
.hm_view_profile_detail span img{width: 14px;margin-right: 8px;}
.hm_view_profile_detail p{margin:0px;color:#8a8a8a;padding-top: 5px;font-size: 12px;}
.hm_view_profile_detail p strong{color:#162d38;font-size:18px;}
.hm_view_tab_bay{width:100%; padding-bottom: 10px;padding-top:10px;}
.hm_view_tab_bay li{float:left;list-style:none;padding: 10px;}
.hm_view_tab_bay li a{text-decoration:none;color:#162d38;font-weight:600;border-bottom:3px solid transparent;padding-bottom:5px;font-size: 17px;}
.hm_view_tab_bay .active a{border-bottom:3px solid #faae3f !important;}
.hm_chat_btn{width:47%;float:left;height:40px;border-radius:20px;border:2px solid #f9ae3e;color:#383838;background:transparent;font-size:18px;margin:3px;font-weight:500;}
.hm_call_btn{width:47%;float:right;height:40px;background:#404040;color:#fff;border-radius:20px;border:2px solid #404040;font-size:18px;margin:3px;font-weight:500;}
.hm_chat_btn span img{height:18px;position:relative;margin-left:10px;top: 3px;}
.hm_call_btn span img{height:18px;position:relative;margin-left:10px;top: 3px;}
.hm_call_btn span{position:relative;bottom:2px;}
.hm_chat_btn span{position:relative;bottom:2px;}
.hm_reviews_list{width:100%;}
.hm_reviews_list ul{width:100%;margin:0px;padding:0px;    padding-top: 20px;}
.hm_reviews_list ul li{list-style:none;}
.hm_testimonial_detail p span{margin-right: 8px;}
.hm_testimonial_detail p span img{width:14px;margin-right: 8px;}
.hm_reviews_list ul li .hm_testimoanil_p{padding-top:20px;padding-bottom:30px;}

.hm_view_tab_content{}
.hm_view_tab_content h5{color:#162d38;}
.hm_view_tab_content h4{color:#767676;}
.hm_view_tab_content p{color:#767676;}
.hm_myprofile_wrapper {
    margin-top: 14px;
}
.hm_myprofile_wrapper ion-card{
	width:100%;
	margin:0px;
	margin-bottom: 10px;
}

.hm_myprofile_wrapper .ion-ios-arrow-forward {
	float:right;
	font-size: 2.0em;
	font-weight: 200;
	margin-top:-32px;
}
.hm_myprofile_wrapper .hm_prof_pic h2{
	margin-left: 5px;

}
.hm_myprofile_wrapper .hm_prof_pic p{
	margin-left: 5px;

}
.hm_sign_bg .scroll-content {
	padding-top: 16px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-bottom: 16px !important;

}
.cha_below_icons ion-avatar {
    padding-top: 8px;
    padding-left: 20px;
}

.chadvert .card-ios {
	width: 100%;
	margin: 0px;
}
.hm_sign_bg .scroll-content {
	margin-top: 6% !important;
}
.hm_header .back-button-icon-md{
	font-size: 2.0rem !important;
	color: #b5e3ff;

}
.hm_headr .toolbar-md {
    min-height: 50px !important;
}
.hm_menu_topbg{
	background-color: #162d38 !important;
	padding: 10%;
	color: #b5e3ff;
}
.hm_plane p{
	padding-left: 20%;
	}

.topup_page ion-card.card.card-md {
    margin-top: 30px;
}



.topup_page ion-item.item.item-block.item-md {
	padding: 20px;
    
}

.cha_inner {
	background-color: #4995bd !important;

}

.topup_page .hm_sign_btn {
	margin-top: 20px;
    margin-bottom: 20px;


	}
.topup_page .hm_mobile_code {
    padding-top: 5px;
}


.popover-wrapper .popover-content {
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.25);
}


