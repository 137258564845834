// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import "theme/sourcesanspro.scss";
@import "../node_modules/ionic4-auto-complete/auto-complete";
@import "../node_modules/animate.css/animate.min.css";




 
.cart-modal {
  --height: 78%;
  --border-radius: 10px;
  padding: 10px;
  
}



ion-header ion-toolbar ion-buttons{
  margin-top: 2% !important;
}
ion-header ion-toolbar ion-title{
  margin-top: 2% !important;
}

ion-header .hm_header_location_bay{
  margin-top: 4% !important;
}
.ion-color-d3blue {
  --ion-color-base: #0c96df;
  --ion-color-base-rgb: 12, 150, 223;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #0f80cf;
  --ion-color-tint: #1ba0ec;
}
.ion-color-d3green {
  --ion-color-base: #00c06d;
  --ion-color-base-rgb: 0, 192, 109;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #00c06d;
  --ion-color-tint: #00c06d;
}

.ion-color-rose {
  --ion-color-base: #FF7D91;
  --ion-color-base-rgb: 255, 125, 145;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #FF7D91;
  --ion-color-tint: #FF7D91;
}
.searchbar-input{
    border-radius: 25px !important;
}
.searchbar-search-icon.sc-ion-searchbar-md {
       left: 88% !important;
  }
  .searchbar-clear-button.sc-ion-searchbar-md {
    left: 13px !important;
    right: 0 !important;
  }


.toolbar-background {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: #2b404a !important;
}

.my-custom-modal-css .modal-wrapper {
    height: 55%;
    top: 20%;
    position: absolute;
    display: block;
    width: 90%;
}

.my-custom-modal-css-full .modal-wrapper {
    height: 80%;
    top: 20%;
    position: absolute;
    display: block;
    width: 100%;
    /*background: transparent !important;*/
}


.my-custom-modal-css-full{
    padding: 35vh 30px 20vh 30px;
    background: rgba(0, 0, 0, 0.5);
}
.sc-ion-modal-md-h {
    --background: none;
}
.sc-ion-modal-ios-h {
    --background: none;
}


.alert-wrapper.sc-ion-alert-ios{
  min-width: 90%

}

.alert-wrapper.sc-ion-alert-md{
  min-width: 90%
  
}






