@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/sourcesanspro-black-webfont.woff2') format('woff2'), url('../assets/fonts/sourcesanspro-black-webfont.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/sourcesanspro-bold-webfont.woff2') format('woff2'), url('../assets/fonts/sourcesanspro-bold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/sourcesanspro-extralight-webfont.woff2') format('woff2'), url('../assets/fonts/sourcesanspro-extralight-webfont.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/sourcesanspro-light-webfont.woff2') format('woff2'), url('../assets/fonts/sourcesanspro-light-webfont.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/sourcesanspro-regular-webfont.woff2') format('woff2'), url('../assets/fonts/sourcesanspro-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/sourcesanspro-semibold-webfont.woff2') format('woff2'), url('../assets/fonts/sourcesanspro-semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}